/* 
Social icons
*/

section #services {
  text-align: center;
  transform: translatez(0);
}
section #services li {
  width: 75px;
  height: 33px;
  display: inline-block;
  list-style: none;
}
section #services li div {
  width: 63px;
  color: #74d4b3;
  font-size: 1.4em;
  text-align: center;
  background-color: #fff;
  transition: all 0.5s ease;
}

.facebook {
  background: #3b5998;
  color: white;
}
section #services li a {
  color: #77cc6d;
}
section #services li div:hover {
  transform: rotate(360deg);
  border-radius: 80px;
}

.social-tags ul {
  position: absolute;
  top: 7%;
  left: 86%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  display: flex;
}
.social-tags ul li {
  list-style: none;
  margin: 0 30px;
}
.social-tags ul li .fab {
  font-size: 29px;
  color: #262626;
  line-height: 44px;
  transition: 0.5s;
}
.social-tags ul li a {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  text-align: center;
  transform: perspective(101px) rotate(-28deg) skew(25deg) translate(0, 0);
  transition: 0.5s;
  box-shadow: -4px 8px 20px rgb(0 0 0 / 50%);
}
.social-tags ul li a::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -20px;
  height: 100%;
  width: 20px;
  background: #b1b1b1;
  transition: 0.5s;
  transform: rotate(0deg) skewY(-45deg);
}
.social-tags ul li a::after {
  content: "";
  position: absolute;
  top: 40px;
  left: -10px;
  height: 20px;
  width: 100%;
  background: #b1b1b1;
  transition: 0.5s;
  transform: rotate(0deg) skewX(-45deg);
}
.social-tags ul li a:hover {
  transform: perspective(1000px) rotate(-30deg) skew(25deg)
    translate(20px, -20px);
  box-shadow: -50px 50px 50px rgb(0, 0, 0, 0.5);
}
.social-tags ul li:hover .fab {
  color: #fff;
}
.social-tags ul li a:hover {
  transform: perspective(1000px) rotate(-30deg) skew(25deg)
    translate(20px, -20px);
  box-shadow: -50px 50px 50px rgb(0, 0, 0, 0.5);
}
.social-tags ul li:hover:nth-child(2) a {
  background: #3b5999;
}

.social-tags ul li:hover:nth-child(2) a:before {
  background: #2e4a86;
}
.social-tags ul li:hover:nth-child(2) a:after {
  background: #4a69ad;
}
.social-tags ul li:hover:nth-child(1) a {
  background: #00a884;
}
.social-tags ul li:hover:nth-child(1) a:before {
  background: #3d7569;
}
.social-tags ul li:hover:nth-child(1) a:after {
  background: #48c1a6;
}

.social-tags ul li:hover:nth-child(3) a {
  background: linear-gradient(#400080, transparent),
    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

.social-tags ul li:hover:nth-child(3) a:before {
  background: linear-gradient(#400080, transparent),
    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}
.social-tags ul li:hover:nth-child(3) a:after {
  background: linear-gradient(#400080, transparent),
    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
}

.profile-card-2 {
  /* max-width: 300px; */
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background-position: center;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 10px;
}

.profile-card-2 img {
  transition: all linear 0.25s;
}

.profile-card-2 .profile-name {
  position: absolute;
  left: 30%;
  bottom: 50%;
  font-size: 30px;
  color: #fff;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-icons {
  position: absolute;
  bottom: 30px;
  right: 30px;
  color: #fff;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-username {
  position: absolute;
  bottom: 50px;
  left: 30px;
  color: #fff;
  font-size: 13px;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-icons .fa {
  margin: 5px;
}

.profile-card-2:hover img {
  filter: grayscale(100%);
}

.profile-card-2:hover .profile-name {
  bottom: 80px;
}

.profile-card-2:hover .profile-username {
  bottom: 60px;
}

.profile-card-2:hover .profile-icons {
  right: 40px;
}
