.header {
  background-image: url("images/header_bg.jpg");
}

.small-social-tag {
  /* margin: 22px; */
}

.small-social-icons {
  font-size: 30px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .social-tags {
    display: block;
  }
  .small-social-tag {
    display: none;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 700px) {
  .social-tags {
    display: none;
  }
  .small-social-tag {
    display: block;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .social-tags {
    display: none;
  }
  .small-social-tag {
    display: block;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .social-tags {
    display: none;
  }
  .small-social-tag {
    display: block;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .social-tags {
    display: none;
  }
  .small-social-tag {
    display: block;
  }
}
